<template>
    <div class="cafe-gal">
        <agile class="main cafe-gallery" ref="main" :options="options1" :as-nav-for="asNavFor1">
            <div class="slide" v-for="(slide, index) in slides" :key="index" :class="`slide--${index}`">
                <img :src="'../images/cafeimg/'+slide"/>
            </div>
            <template slot="prevButton"><div class="btn-round"><fa :icon="faAngleLeft"/></div></template>
            <template slot="nextButton"><div class="btn-round"><fa :icon="faAngleRight"/></div></template>
        </agile>
    </div>
</template>

<script>

import Fa from 'vue-fa';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

export default {
    
    name: "CafeCarousel",
    components: { Fa },
    data() {
        return {
            faAngleLeft,
            faAngleRight,
            asNavFor1: [],
            asNavFor2: [],
            options1: {
                autoplaySpeed: 1500,
                speed: 100,
                fade: false,
                centerMode: true,
                timing: 'ease-in-out',
                slidesToShow: window.innerWidth > 1600 ? 4 : window.innerWidth < 720 ? 2 : 3,
                dots: false,
                responsive: [
                    {
                        breakpoint: 400,
                        settings: {
                            navButtons: true,
                            autoplay: true,
                            dots: false
                        }
                    },           
                    {
                        breakpoint: 1000,
                        autoplay: true,
                        settings: {
                            navButtons: true,
                            dots: false
                        }
                    }
                ]

            },
            slides: [
                '6Hyb6W6K.jpg',
                '10nId79k.jpg',
                '350dEoDP.jpg',            
                'Bel9gA6m.jpg',
                'sycYLkQ0.jpg'
             //   'zd4c6Pi1.jpg'
            ]
        }
    },
	created () {
		this.asNavFor1.push(this.$refs.thumbnails)
        this.asNavFor2.push(this.$refs.main)
	}
    
}
</script>