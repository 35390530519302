<template>
    <div class="info-break my-5" data-aos="fade-right" :data-aos-offset="offset">
        <h2 class="subtitle mx-3">
            <b-row>
                <div>
                    <img src="/assets/images/logo_sm.svg"/>
                </div>
                <div class="text">
                    <span>{{ $props.text1 }} <b>{{ $props.textBold }}</b><br> {{ $props.text2 }}</span>
                </div>
            </b-row>
            
     
        </h2>
    </div>
</template>

<script>

export default {
    name: "InfoBreak",
    props: ['icon', 'text1', 'text2', 'textBold'],
    data() {
        return {
            offset: window.innerWidth > 1600 ? 200 : window.innerWidth < 720 ? 70 : 120
        }
    }
}

</script>