<template>
    <div class="animations-container animations col-md-11">
        <h2 class="subtitle mb-5">Nowość! <br><br> Organizujesz wesele bądź inną imprezę? Kids Play Jupiter zorganizuje niezapomniane animacje dla Twoich najmłodszych gości!</h2>
        <b-card-group deck>
            <b-card
                class="border-0 offer"
                img-src="../images/VLWf7jNTsdj.jpg"
                img-alt="Animacje"
            >
                <template #footer>
                    <a href="./files/KidsPlay_Jupiter_Oferta_Animacje.pdf" target="_blank">Kliknij aby pobrać naszą ofertę</a>
                </template>
            </b-card>
        </b-card-group>
        <h2 class="subtitle banner my-4">
            <a href="https://www.weselezklasa.pl/ogloszenia-weselne/kids-play-jupiter-kosmiczna-zabawa,57005/" target="_blank" title="Kids Play Jupiter-kosmiczna zabawa">
                <img src="https://www.weselezklasa.pl//banery/Weselezklasa/ogloszenie750x200_1.gif" alt="Kids Play Jupiter-kosmiczna zabawa" border="0" />
            </a>
        </h2>
        <info-break
            textBold="Zapraszamy do kontaktu!"
        />
    </div>
</template>

<script>
    import InfoBreak from "./InfoBreak.vue";
    export default {
        name: "AnimationsSection",
        components: { InfoBreak }
    }
</script>