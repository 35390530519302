<template>
    <div class="gallery" :class="shorten == true ? 'shorten' : ''">
        <viewer :images="images" :options="options">
            <div class="images m-3">
                <div class="item" v-for="src in images" :key="src">
                    <img :src="'../images/'+src"/>
                </div>
                <div class="item" v-for="src in imagesBday" :key="src">
                    <img :src="'../images/bday/'+src"/>
                </div>
                <div class="item" v-for="src in imagesBS" :key="src">
                    <img :src="'../images/bbshower/'+src"/>
                </div>
            </div>            
        </viewer>
        <p class="btn-expand zoom" 
            @click="shorten = !shorten"
            v-scroll-to="{el: '#gallery', offset: -60 }">
            <fa :icon="faAngleDown" :class="shorten == false ? 'rotate' : ''" />
        </p>
    </div>
</template>

<script>

import Fa from 'vue-fa';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

export default {
    
    name: "GallerySection",
    components: { 
        Fa
    },
    data() {
        return {
            faAngleDown,
            shorten: true,
            options: { 
                "button": false, 
                "navbar": true, 
                "title": false, 
                "toolbar": true, 
                "tooltip": false, 
                "movable": false, 
                "zoomable": false, 
                "rotatable": false, 
                "scalable": false, 
                "transition": false, 
                "fullscreen": true, 
                "keyboard": true,
                
            },
            images: [
                'FPXDzyXL.jpg',
                'O8d5876f.jpg',                
                '0ILnNmPX.jpg',
                '4v0ag97P.jpg',
                'em6942w4.jpg',
                'HKsOaX4t.jpg',
                '837USPhW.jpg',
                'HxPeP6PB.jpg',
                '5YvNs1E1.jpg',
                '576DmBOr.jpg',
                'Jh2UT2gU.jpg'
            ],
            imagesBday: [
                '12L5F3jI.jpg',
                '1D2IzJ59.jpg',
                '1t0OJ7Ca.jpg',
                '2YwGji31.jpg',
                '34A3hOP7.jpg',
                '40HbTlX9.jpg',
                '6s74YxRq.jpg',
                '8h06Go13.jpg',
                'AD5CVh2R.jpg',
                'Bm2UfFyb.jpg',
                'D7CS7hs6.jpg',
                'DC3KZ2jl.jpg',
                'E49O7E5g.jpg',
                'H8X9K1r5.jpg',
                'J8373t2q.jpg',
                'Jy6folOc.jpg',
                'L91dsY0y.jpg',
                'N8Q3e6fv.jpg',
                'U3fr1lHg.jpg',
                'U4iVrQ2m.jpg',
                'VLWf7jNTt.jpg',
                'XGM42IUV.jpg',
                'YM20cXAQ.jpg',
                'Zpw6l9T2.jpg',
                'ijM76LAP.jpg',
                'jfW1vpBP.jpg',
                'l6AhY2H2.jpg',
                'mx1FeaT3.jpg',
                'nF329J4B.jpg',
                'oiE0czT8.jpg',
                'p4Tnp6S3.jpg',
                'qO07Af3b.jpg',
                'raY1Y5p0.jpg',
                'sN3epP7a.jpg',
                'sdcWlNHR.jpg',
                'tzjRTpl2.jpg',
                'xbKCWe1t.jpg',
                '5VNO6GC1.jpg',
                'P3qYo3F5.jpg',
                '4M14b93K.jpg',
                'VzS1v34p.jpg',
                '3ZWMm1pF.jpg',
                'uQzBZ378.jpg',
                '4X5v5XPu.jpg',
                'WPWOdPup.jpg',
                'K9Ns5SQ0.jpg',
                'hmWX17dj.jpg',
                's9V7TI50.jpg',
                'pJ6s4ciV.jpg',
                '0QbR7tMU.jpg',
                '0f20eo94.jpg',
                '58tG4X1v.jpg',
                '5T86O8yD.jpg',
                'Bnyf7zbR.jpg',
                'D1WLPf6E.jpg',
                'DLD5Q8pD.jpg',
                'K24m1t4b.jpg',
                'LTxU15M5.jpg',
                'Ngq0lM8c.jpg',
                'O2LIPwCH.jpg',
                'Vkuvkjum.jpg',
                'cSbO5QIw.jpg',
                'z6ahW8bv.jpg'
            ],
            imagesBS: [
                '2CEWvMq6.jpg',
                '3IW2gzVZ.jpg',
                '625qSAmY.jpg',
                '9qv63Aij.jpg',
                'SZC8WOEd.jpg'
            ]
        }
    },
	created () {
		this.asNavFor1.push(this.$refs.thumbnails)
        this.asNavFor2.push(this.$refs.main)
	}
    
}
</script>