<template>
    <div class="rules-container col-md-11">
        <info-break
            textBold="Drodzy rodzice i opiekunowie!"
            text2="Przed rozpoczęciem przygody na Sali zabaw Kids Play „Jupiter” prosimy o zapoznanie się z obowiązującymi zasadami"
        />
        <ol>
            <li class="main-list" v-for="(textItem, index) in textList" :key="index">{{ textItem.text }}
                <ul class="secondary-level" :v-if="textItem.sublevel" >
                    <li v-for="(subTextItem, subindex) in textItem.sublevel" :key="subindex">{{ subTextItem }}</li>
                </ul>
            </li>


        </ol>
        <info-break
            textBold="Życzymy kosmicznej zabawy! :)"
        />
    </div>
</template>

<script>
import InfoBreak from "./InfoBreak.vue";
import rulesText from "../../public/json/rules.json"
export default {
    name: "RulesSection",
    components: { InfoBreak },
    data() {
        return {
            textList: rulesText
        }
    }
}
</script>