<template>
    <div class="prices-container">
        <info-break
                text1=" Cennik obowiązuje od"
                textBold="01.01.2024"
        />
        <table class="price-table main">
            <tr>
                <th></th>
                <th>Poniedziałek - Czwartek<br>do 15.00</th>
                <th>Poniedziałek - Czwartek<br>od 15.00</th>
                <th>Piątek - Niedziela <br>
                    i święta</th>
                <th>Opieka</th>
            </tr>
            <tr v-for="(textItem, index) in textList" :key="index" >
                <td :class="textItem.tr ? 'hours' : 'variant'" :colspan="textItem.tr ? (textItem.tr+1) : false">{{ textItem.header }}</td>
                <td  v-for="(td) in textItem.td" :key="td">{{ td }}</td>
            </tr>
        </table>
        <info-break
                text1=" Dzieci poniżej 3 roku życia zapraszamy do zabawy wyłącznie w"
                textBold="Strefie Malucha"
                text2="(pod opieką rodzica)"
        />
        <table class="price-table main mb-5">
            <tr>
                <th></th>
                <th>Poniedziałek - Czwartek<br>do 15.00</th>
                <th>Poniedziałek - Czwartek<br>od 15.00</th>
                <th>Piątek - Niedziela <br>
                    i święta</th>
            </tr>
            <tr v-for="(textItem, index) in textListBaby" :key="index" >
                <td :class="textItem.tr ? 'hours' : 'variant'" :colspan="textItem.tr ? (textItem.tr+1) : false">{{ textItem.header }}</td>
                <td  v-for="(td) in textItem.td" :key="td">{{ td }}</td>
            </tr>
        </table>
    </div>
</template>

<script>
import InfoBreak from "./InfoBreak.vue";
import pricesText from "../../public/json/prices_0124";
import pricesTextBaby from "../../public/json/prices_baby_0124";

export default {
    name: "PricesSection",
    components: { InfoBreak },
    data() {
        return {
            textList: pricesText,
            textListBaby: pricesTextBaby
        }
    }
}
</script>