<template>
    <div>
        <h2>{{ name }}</h2>
            <div style="margin: 2% 0px;">
                 <b-button variant="outline-secondary">
                    <span class="icon"><fa :icon="faEnvelope"/></span>
                    <span class="contact-element">{{ postalCode }} {{ city }} {{ address }}</span>
                 </b-button>
                <b-button variant="outline-secondary">
                    <span class="icon"><fa :icon="faAt"/></span>
                    <span class="contact-element">{{ email }}</span>
                </b-button>
                <b-button variant="outline-secondary" @click="callPhoneNumber(phone)">
                    <span class="icon"><fa :icon="faPhone"/></span>
                    <span class="contact-element">tel. {{ phone }}</span>
                </b-button>                
                <b-button variant="outline-secondary" @click="callPhoneNumber(mobile)">
                    <span class="icon"><fa :icon="faMobileAlt"/></span>
                    <span class="contact-element">tel. {{ mobile }}</span>
                </b-button>
                <b-button variant="outline-secondary" @click="findRoute">
                    <span class="icon"><fa :icon="faMapMarkerAlt"/></span>
                    <span class="contact-element">
                        Jak dojechać?
                    </span>
                </b-button>
                <h2 class="mt-4">Godziny otwarcia</h2>
                <ul class="open-hours-main">
                    <li v-for="day in openHours" :key="day.index" >
                        <p class="open-hours day">{{ day.name }}.</p>
                        <p class="open-hours hours">{{ day.hours }}</p>
                    </li>
                </ul>
            </div>
    </div>
</template>

<script>
    import Fa from 'vue-fa';
    import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
    import { faMapMarkerAlt, faAt, faMobileAlt, faPhone } from '@fortawesome/free-solid-svg-icons';

    export default {
        name: 'ContactSection',
        components: {
            Fa
        },
        data() {
            return {
                faEnvelope,
                faAt,
                faMapMarkerAlt,
                faMobileAlt,
                faPhone,
                name: 'Park Zabaw Kids Play Jupiter',
                city: 'Żory',
                address: 'ul. Katowicka 10',
                postalCode: '44-240',
                phone: '32 733 86 91',
                mobile: '660 850 305',
                email: 'kidsplayjupiter@gmail.com',
                mapTarget: 'https://www.google.com/maps/dir//Galeria+Wi%C5%9Blanka,+DW935+nr+81,+43-267+%C5%BBory/@50.044541,18.7017781,19.5z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4716b3c804f0b2af:0x64db8fc2a0424b0a!2m2!1d18.7030319!2d50.0448892!3e0',
                openHours: [
                    { name: 'Pn', hours: '12:00 - 21:00'},
                    { name: 'Wt', hours: '12:00 - 21:00'},
                    { name: 'Śr', hours: '12:00 - 21:00'},
                    { name: 'Cz', hours: '12:00 - 21:00'},
                    { name: 'Pt', hours: '09:00 - 21:00'},
                    { name: 'Sb', hours: '09:00 - 21:00'},
                    { name: 'Nd', hours: '10:00 - 20:00'}
                ]                  
            }
        },
        methods: {
            sendEmail() {
                let x = window.location.href="mailto: "+this.email;
                x.close();
            },
            callPhoneNumber(number) {
                window.open('tel:+48 '+ number, '_blank');
            },
            findRoute() {
                window.open(this.mapTarget, '_blank');
            }
        }
    }
</script>