<template>
    <div id="top" class="default-box">
        <b-navbar v-b-scrollspy="100" :class="scrollpx < 800 ? (scrollpx < 300 ? 'hidden-mobile': 'hidden') : 'show'" toggleable="xl" type="dark" variant="dark" fixed="top">
            <b-navbar-brand v-scroll-to="{el: '#top', offset: -60 }">
                <img src="/assets/images/logo_sm.svg" class="logo round zoom">
                <span class="title">Kids Play Jupiter</span>
            </b-navbar-brand>

            <b-navbar-toggle target="nav-collapse">
            </b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav right>
            <b-navbar-nav class="ml-auto">
              <b-nav-item href="#news" v-scroll-to="{el: '#news', offset: -60 }">Nowości</b-nav-item>                 
              <b-nav-item href="#start" v-scroll-to="{el: '#start', offset: -60 }">Nasz Park</b-nav-item>           
              <b-nav-item href="#gallery" v-scroll-to="{el: '#gallery', offset: -60 }">Galeria</b-nav-item>
              <b-nav-item href="#prices" v-scroll-to="{el: '#prices', offset: -60 }">Cennik</b-nav-item>
              <b-nav-item href="#bday" v-scroll-to="{el: '#bday', offset: -60 }">Urodzinki</b-nav-item>
              <b-nav-item href="#bbshower" v-scroll-to="{el: '#bbshower', offset: -60 }">Baby Shower</b-nav-item>
              <b-nav-item href="#cafe" v-scroll-to="{el: '#cafe', offset: -60 }">Kawiarenka</b-nav-item>
              <!-- <b-nav-item href="#rules" v-scroll-to="{el: '#rules', offset: -60 }">Regulamin</b-nav-item> -->
              <b-nav-item href="#animations" v-scroll-to="{el: '#animations', offset: -60 }">Animacja Imprez</b-nav-item>
              <b-nav-item href="#about" v-scroll-to="{el: '#about', offset: -60 }">O nas</b-nav-item>
              <b-nav-item href="#contact" v-scroll-to="{el: '#contact', offset: -60 }">Kontakt</b-nav-item>
            </b-navbar-nav>
            </b-collapse>
        </b-navbar>

        <div class="home"> 
          <div class="star-background behind"></div>
          <div class="star-background moving"></div>
            <MainPage/>
        </div>
        <a href="https://www.facebook.com/kidsplayjupiter/" target="_blank" class="media-button zoom"><fa :icon="faFacebook"/></a>
        <div class="copyright">
          © Copyright - Kids Play Jupiter 2023
        </div>
    </div>
</template>

<script>
import MainPage from '../components/MainPage.vue'
import Fa from 'vue-fa';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';

export default {
  name: 'BaseLayout',
  components: {
    MainPage,
    Fa
  },
  data() {
      return {
        faFacebook,
        scrollpx: 0
      }
  },
  methods: {
    handleScroll() {
        this.scrollpx = window.scrollY;
      }
    },
    created() {
      window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
      window.removeEventListener('scroll', this.handleScroll);
    }

}
</script>