<template>
    <div class="cafe-container">
        <info-break
            text1="Serdecznie zapraszamy do "
            textBold="Kawiarenki Jupiterka!"
        />
        <cafe-carousel></cafe-carousel>
        <table class="price-table two-col main mb-5">
            <tr v-for="(textItem, index) in textList" :key="index" >
                <td :class="textItem.tr ? 'hours' : 'variant'" :colspan="textItem.tr ? (textItem.tr) : false">{{ textItem.name }} 
                    <fa v-if="textItem.season=='summer'" color="yellow" :icon="faSun"/>
                    <fa v-if="textItem.season=='winter'" color="cyan" :icon="faSnowflake"/>
                </td>
                <td v-if=" textItem.price">{{ textItem.price }}</td>
            </tr>
            <tr>
                <td class="hours" colspan=2> 
                    <div style="display: flex;justify-content: space-evenly;">
                        <span><fa color="yellow" :icon="faSun"/> - tylko w sezonie letnim</span>
                        <span><fa color="cyan" :icon="faSnowflake"/> - tylko w sezonie zimowym</span>
                    </div>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
    import InfoBreak from "./InfoBreak.vue";
    import CafeCarousel from "./CafeCarousel.vue";
    import Fa from 'vue-fa';
    import { faSnowflake } from '@fortawesome/free-regular-svg-icons';
    import { faSun } from '@fortawesome/free-solid-svg-icons';
    import pricesText from "../../public/json/cafe.json";

    export default {
        name: "CafeSection",
        components: { Fa, InfoBreak, CafeCarousel },
        data() {
            return {
                textList: pricesText,
                faSun, 
                faSnowflake
            }
        }
    }
</script>
