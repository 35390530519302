<template>
  <div class="main">
        <parallax :speed-factor="0.2" breakpoint="(min-width: 968px)">        
          <p>
            <img src="/assets/images/jupiter.svg" class="jupiter">
          </p>
          <p>
            <img src="/assets/images/logo.svg" class="logo">
          </p>            
          <p>
            <img src="/assets/images/astronaut.svg" class="astronaut">
          </p>
          <p class="start-descr">
            <span class="start" v-scroll-to="{el: '#news', offset: -60 }">           
              <span>Rozpocznij Przygodę!</span>            
            </span>
          </p>
        </parallax>

        <div class="main-raised">        
          <section id="news">
            <h1>Nowości</h1>
            <news-section/>
          </section>
          <section id="start">
            <h1>Nasz park</h1>
            <park-section/>
          </section>       
          <section id="gallery">
            <h1>Galeria</h1>
            <gallery-section/>
          </section>
          <section id='prices'>
            <h1>Cennik</h1>
            <prices-section/>
          </section>              
          <section id='bday'>
            <h1>Urodzinki</h1>
            <birthday-section/>
          </section>
          <section id='bbshower'>
            <h1>Baby Shower</h1>
            <bb-shower/>
          </section>     
          <section id='cafe'>
            <h1>Kawiarenka</h1>
            <cafe-section/>
          </section>     
          <section id='animations'>
            <h1>Animacja Imprez</h1>
            <animations-section/>
          </section>     
          <section id='rules'>
            <h1>Nasz Regulamin</h1>
            <rules-section/>
          </section>
          <section id='about'>
            <h1>O nas</h1>
            <about-section/>
          </section>
          <section id='contact' class="last">
            <h1>Kontakt</h1>
            <div class="contact-main">
              <div class="map-container">
                  <map-section/>
              </div>
              <div class="contact-container">
                  <contact-section/>
              </div>
            </div>
            <info-break
                textBold="Do zobaczenia! :)"
            />
            <div style="height: 1.5rem"></div>
          </section>
        </div>
    </div>
</template>

<script>
import Parallax from "vue-parallaxy";
import GallerySection from "./Gallery.vue";
import ParkSection from "./Park.vue";
import NewsSection from "./News.vue";
import AboutSection from "./About.vue";
import RulesSection from "./Rules.vue";
import PricesSection from "./Prices.vue";
import BirthdaySection from "./Birthday.vue";
import BbShower from "./BbShower.vue";
import CafeSection from "./Cafe.vue";
import AnimationsSection from "./Animations.vue";
import MapSection from "./Map.vue";
import ContactSection from "./Contact.vue";
import InfoBreak from "./InfoBreak.vue";

export default {
  name: "MainPage",
  components: {
    Parallax,
    GallerySection,
    ParkSection,
    NewsSection,
    AboutSection,
    RulesSection,
    PricesSection,
    BirthdaySection,
    BbShower,
    CafeSection,
    AnimationsSection,
    MapSection,
    ContactSection,
    InfoBreak
  }
}


</script>

