<template>
    <div class="offer-container bb-shower">
        <b-carousel deck class="main-list mb-5 bb-shr" 
            id="carousel-1"
            :interval="slideInterval"
            controls
            indicators
            img-width="1024"
            img-height="480"
            style="text-shadow: 1px 1px 2px #333;"
        >
            <b-carousel-slide 
                v-for="(textItem, index) in textList" :key="index"
                :caption="textItem.header" 
                :header="'Prices'"
                :img-src="'../images/bbshower/'+textItem.img"
            >
                <div class="slide-info">
                    <ul>
                        <li v-for="(item, itemIndex) in textItem.text" :key="itemIndex">{{ item }}</li>
                    </ul>
                    <div class="slide-price">
                    <p v-for="(item, itemIndex) in textItem.price" :key="itemIndex"> {{ item }} </p>
                    </div>
                </div>
            </b-carousel-slide>
        </b-carousel>
        <b-card-group deck>
            <b-card
                class="border-0"
                no-body
                img-src="../images/bbshower/9qv63Aij.jpg"
                img-alt="Konkursy"
            >
                <h2 class="title">W cenie imprezy</h2>
                <b-card-body>

                    <b-list-group flush>
                        <b-list-group-item><b>Dekoracja miejsca imprezy oraz stołu dla uczestniczek</b></b-list-group-item>
                        <b-list-group-item><b>Opieka osoby prowadzącej</b></b-list-group-item>
                        <b-list-group-item><b>3 konkursy (do wyboru) dla przyszłej mamy i przyjaciółek</b></b-list-group-item>
                        <b-list-group-item><b> Napoje: woda z cytryną, herbata, kawa</b></b-list-group-item>
                        <b-list-group-item><b>Jedzenie - w zależności od pakietu</b></b-list-group-item>
                    </b-list-group>
                </b-card-body>

            </b-card>
            <b-card
                class="border-0"
                no-body
                img-src="../images/bbshower/SZC8WOEd.jpg"
                img-alt="Konkursy"
            >
                <h2 class="title">Dostępne konkursy</h2>
                <b-card-body>

                    <b-list-group flush>
                        <b-list-group-item><b>"Dzieci celebrytów"</b></b-list-group-item>
                        <b-list-group-item><b>"Ile cm w pasie ma przyszła mama?"</b></b-list-group-item>
                        <b-list-group-item><b>"Kto pierwszy?"</b><br>– przebieranie bobasa z chustą na oczach</b-list-group-item>
                        <b-list-group-item><b>"Kto jest na zdjęciu?"</b><br> – uczestniczki baby shower jako maluchy</b-list-group-item>
                        <b-list-group-item><b>"Ile trwa ciąża?"</b><br> – ale u zwierzaków ;)</b-list-group-item>
                    </b-list-group>
                </b-card-body>

            </b-card>
        </b-card-group>

        <div class="prices-container">
            <table class="price-table mb-4 two-col">
                <tr><td class="hours" colspan="2">Dodatkowo płatne konkursy</td></tr>
                <tr>
                    <td>"Co to jest?" – słoiczki z deserkami i obiadkami dziecięcymi</td>
                    <td>50 zł</td>
                </tr>
                <tr>
                    <td>"Robimy bransoletkę dla przyszłej mamy"</td>
                    <td>40 zł</td>
                </tr>
                <tr>
                    <td>Dekorowanie śliniaka i body</td>
                    <td>80 zł</td>
                </tr>
            </table>
            <table class="price-table mb-5 two-col">
                <tr><td class="hours" colspan="2">Opcje dodatkowo płatne</td></tr>
                <tr>
                    <td>Soki 100% (karafka 1l)</td>
                    <td>10 zł</td>
                </tr>
                <tr>
                    <td>Napoje gazowane (Pepsi, Mirinda), 1l</td>
                    <td>15 zł</td>
                </tr>
                <tr>
                    <td>Lemoniada, 1szt.</td>
                    <td>8 zł</td>
                </tr>
                <tr>
                    <td>Muffiny (z kremem,  z kremem i ozdobą z lukru plastycznego)</td>
                    <td>130 zł</td>
                </tr>
                <tr>
                    <td>Profesjonalna fotorelacja (zdjęcia na płycie CD)</td>
                    <td>Cena do uzgodnienia</td>
                <tr>
                    <td>"Tort z pieluch" - wyprawka dla przyszłej mamy</td>
                    <td>Cena do uzgodnienia</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import pricesText from "../../public/json/bbshower.json"
export default {
    name: "BbShower",
    data() {
        return {
            textList: pricesText,
            slideInterval: window.innerWidth < 720 ? 0 : 5000
        }
    }
}
</script>