<template>
    <div class="offer-container">
        <h2 v-if="false" class="subtitle mx-5">Ceny obowiązują od <b>01.01.2024</b></h2>
        <b-carousel deck class="main-list mb-5" 
            id="carousel-1"
            :interval="slideInterval"
            controls
            indicators
            img-width="1024"
            img-height="480"
            style="text-shadow: 1px 1px 2px #333;"
        >
            <b-carousel-slide 
                v-for="(textItem, index) in textList" :key="index"
                :caption="textItem.header" 
                :header="'Prices'"
                :img-src="'../images/bday/'+textItem.img"
            >
                <div class="slide-info smaller-font ">
                    <ul>
                        <li v-for="(item, itemIndex) in textItem.text" :key="itemIndex">{{ item }}</li>
                    </ul>
                    <div class="slide-price">
                        <p v-for="(item, itemIndex) in textItem.price" :key="itemIndex"> {{ item }} </p>
                    </div>
                </div>
            </b-carousel-slide>
        </b-carousel>
        <!-- <info-break
            textBold="Nowość!"
            text2="Zamów bajkowy tort w Kidsplay Jupiter!"
        />
        <bcake-carousel></bcake-carousel> -->
        <info-break
            text1="Dzieci korzystające z atrakcji na naszej sali zabaw muszą obowiązkowo posiadać"
            text2="Można je zakupić w recepcji w cenie 10zł"
            textBold="skarpetki antypoślizgowe."
        />
        <info-break
            text1="Rezerwacja stolika dla rodziców -"
            text2="(bez poczęstunku)"
            textBold="5zł/osoba dorosła"
        />
        <div class="prices-container">
            <table class="price-table two-col">
                <tr><td class="hours" colspan="2">Opcje dodatkowo płatne:</td></tr>
                <tr>
                    <td>Talerz świeżych owoców</td>
                    <td>60 zł</td>
                </tr>
                <tr>
                    <td>Piniata</td>
                    <td>200 zł</td>
                </tr>
                <tr>
                    <td>Szampan dla dzieci</td>
                    <td>20 zł</td>
                </tr>
                <tr>
                    <td>Kolorowe warkoczyki</td>
                    <td>15 zł</td>
                </tr>
                <tr>
                    <td>Malowanie buzi</td>
                    <td>5 zł</td>
                </tr>
                <tr>
                    <td>Tatuaże brokatowe</td>
                    <td>10 zł</td>
                </tr>
                <tr>
                    <td>Plakat dla solenizanta/tki wykonywany wspólnie z dziećmi</td>
                    <td>60 zł</td>
                </tr>
                <tr>
                    <td>Zamykanie w bańce mydlanej</td>
                    <td>60 zł</td>
                </tr>
                <tr>
                    <td>Wniesienie własnego poczęstunku</td>
                    <td>30 zł</td>
                </tr>
                <tr>
                    <td>Wniesienie własnej piniaty/konfetti</td>
                    <td>30 zł</td>
                </tr>
                <tr>
                    <td>Dodatkowy animator</td>
                    <td>200 zł</td>
                </tr>
                <tr>
                    <td>Dodatkowy czas</td>
                    <td>wg cennika</td>
                </tr>
            </table>  
            <info-break
                text1="Więcej informacji pod numerem telefonu"
                textBold="32 733 86 91"
            />
        </div>



    </div>
</template>

<script>
import pricesText from "../../public/json/birthday.json";
//import BcakeCarousel from "./BcakeCarousel.vue";
import InfoBreak from "./InfoBreak.vue";

export default {
    name: "BirthdaySection",
    components: {
     //   BcakeCarousel,
        InfoBreak
    },
    data() {
        return {
            textList: pricesText,
            slideInterval: window.innerWidth < 720 ? 0 : 5000
        }
    }
}
</script>