<template>
    <div class="park-container">
        <h2 class="subtitle mx-5 mb-3">Cieszymy się, że jesteś! Z nami przeżyjesz wspaniałą, kosmiczną przygodę i niezapomnianą zabawę!</h2>
        <div class="main-list">
            <b-carousel deck class="main-list mb-3 full-foto" data-aos="fade-in"
                id="carousel-park-1"
                :interval="slideInterval"
                controls
                indicators
                img-width="1024"
                img-height="480"
                style="text-shadow: 1px 1px 2px #333;"
            >
                <b-carousel-slide 
                    v-for="(textItem, index) in textList" :key="index"
                    :caption="textItem.header" 
                    :text="textItem.text"
                    :header="textItem.date"
                    :img-src="'../images/'+textItem.img"
                >
            </b-carousel-slide>
            </b-carousel>
        </div>
        <info-break
                text1="Jakie jeszcze atrakcje znadzjdziecie w naszym Parku? "
                textBold="Sprawdźcie sami!"
        />
        <div style="height: 1.5rem"></div>
    </div>
</template>

<script>
import InfoBreak from "./InfoBreak.vue";
import aboutParkText from "../../public/json/park.json"
export default {
    name: "ParkSection",
    components: { InfoBreak },
    data() {
        return {
            textList: aboutParkText,
            slideInterval: window.innerWidth < 720 ? 0 : 5000
        }
    }
}
</script>