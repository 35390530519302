<template>
  <div id="app">
    <BaseLayout/>
  </div>
</template>

<script>
import BaseLayout from './layout/BaseLayout.vue'

export default {
  name: 'app',
  components: {
    BaseLayout
  }
}
</script>

<style scoped>
  
</style>

<style lang="scss">
  @import 'node_modules/bootstrap/scss/bootstrap';
  @import 'node_modules/bootstrap-vue/src/index.scss';
  @import 'assets/fonts';
  @import "assets/style";
  @import "assets/vue-agile";
</style>
