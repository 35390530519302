<template>
    <div class="about-container col-md-11">
        <h2 class="subtitle mb-5">Przekonaj się, że warto nam zaufać! Twoje pociechy przeżyją z nami wspaniałą i bezpieczną przygodę!</h2>
        <p class="main-list" v-for="(textItem, index) in textList" :key="index">{{ textItem.text }}</p>
        <a class="mdb-image" href="https://mdbplayground.pl/" target="_blank"><img src="\assets\images\MDB-Playground-300x113.png"/></a>
        <info-break
            textBold="Serdecznie zapraszamy!"
            text2="Dzieci - do niezapomnianej zabawy, zaś rodziców - do strefy relaksu, z pyszną kawą i ulubioną książką, w nowo otwartej Sali Zabaw Kids Play - Jupiter!"
        />
    </div>
</template>

<script>
    import InfoBreak from "./InfoBreak.vue";
    import aboutText from "../../public/json/about.json"
    export default {
        name: "AboutSection",
        components: { InfoBreak },
        data() {
            return {
                textList: aboutText
            }
        }
    }
</script>