<template>
  <div style="width: 100%;">
    <l-map
      gestureHandling
      :zoom="zoom"
      :center="center"
      :options="mapOptions"
      style="height: 100%;"
      @update:center="centerUpdate"
      @update:zoom="zoomUpdate"
    >
      <l-tile-layer
        :url="url"
        :attribution="attribution"
      />
      <l-marker :lat-lng="withTooltip">
        <l-icon
          :icon-size="dynamicSize"
          :icon-anchor="dynamicAnchor"
          icon-url="/assets/images/pin.svg"
          shadow-url="/assets/images/pin_shadow.svg"
        />
      </l-marker>
    </l-map>
  </div>
</template>

<script>
import { latLng } from "leaflet";
import * as L from "leaflet";
import { LMap, LTileLayer, LMarker, LIcon } from "vue2-leaflet";
import { GestureHandling } from "leaflet-gesture-handling";

L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

export default {
  name: "MapSection",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon
  },
  data() {
    return {
      zoom: 16,
      center: latLng(50.0448909,18.7),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      withPopup: latLng(50.0448909,18.7019376),
      withTooltip: latLng(50.0448909,18.703406),
      currentZoom: 16,
      scrollWheelZoom: false,
      currentCenter: latLng(50.0448909,18.7019376),
      showParagraph: false,
      iconSize: 72,
      mapOptions: {
        layerType: 'grayscale',
        zoomSnap: 0.5,
        gestureHandling: true
      }
    };
  },
  computed: {
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.15];
    }
  },
  methods: {
   zoomUpdate(zoom) {
     this.currentZoom = zoom;
   },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    }
  },
  created() {
    if (screen.width < 768) {
        this.zoom = 15.5;
    }  else {    
        this.zoom = 15;
    }
  }
};
</script>